import ReactDOM from 'react-dom';
import { StrictMode, lazy, Suspense } from "react";
import { kcContext as kcLoginThemeContext } from "./keycloak-theme/login/kcContext";
import { kcContext as kcAccountThemeContext } from "./keycloak-theme/account/kcContext";

const KcLoginThemeApp = lazy(() => import("./keycloak-theme/login/KcApp"));
const KcAccountThemeApp = lazy(() => import("./keycloak-theme/account/KcApp"));

const root = document.getElementById('root');

ReactDOM.render(
    <StrictMode>
            <Suspense fallback={null}>
                {(()=>{

                    if( kcLoginThemeContext !== undefined ){
                        return <KcLoginThemeApp kcContext={kcLoginThemeContext} />;
                    }

                    if( kcAccountThemeContext !== undefined ){
                        return <KcAccountThemeApp kcContext={kcAccountThemeContext} />;
                    }

                })()}
            </Suspense>
    </StrictMode>,
    root
);
